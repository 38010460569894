<template>
  <v-container fill-height>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="4">
        <v-card class="elevation-6">
          <v-toolbar color="primary" flat dark>
            <v-toolbar-title>Select new password</v-toolbar-title>
            <v-spacer />
          </v-toolbar>
          <v-card-text>
            <v-form v-model="isValid">
              <v-text-field
                v-model="email"
                label="Email"
                name="email"
                prepend-icon="person"
                type="email"
                :rules="[requiredRule('Epost', true), emailRule()]"
              />
              <v-text-field
                type="password"
                v-model="password"
                label="Password"
                prepend-icon="lock"
                :rules="[requiredRule('Password', true), passwordLengthRule(8)]"
                error-count="2"
              ></v-text-field>
              <v-text-field
                type="password"
                v-model="password_confirmation"
                label="Confirm Password"
                prepend-icon="lock"
                :rules="[
                  requiredRule('Confirm password', true),
                  v => v === password || 'Password does not match'
                ]"
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn color="primary" :disabled="!isValid" @click="resetPassword()"
              >Change password</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { settings } from "@/settings";
import validationRules from "@/validation/rules.js";

export default {
  data() {
    return {
      email: this.$route.params.email,
      password: null,
      password_confirmation: null,
      isValid: true
    };
  },
  methods: {
    resetPassword() {
      this.$store
        .dispatch("auth/password_reset", {
          email: this.email,
          password: this.password,
          password_confirmation: this.password_confirmation,
          token: this.$route.params.token
        })
        .then(res => {
          this.$store.dispatch("alert/show", {
            message: `Password updated. Please try logging back in`,
            type: "info"
          });
        });
    },
    ...validationRules
  }
};
</script>
