import Vue from "vue";
import Router from "vue-router";
import store from "../store";
import Login from "@/views/auth/login";
import ForgotPassword from "@/views/auth/forgot-password";
import ResetPasswordForm from "@/views/auth/reset-password-form";
import App from "@/views/layouts/App";
import Admin from "@/views/admin";
import SuperAdmin from "@/views/superadmin";

Vue.use(Router);

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters["auth/isAuthenticated"]) {
    next();
    return;
  }

  store.dispatch("user/get_profile").then(() => {
    if (store.getters["user/isAdmin"] || store.getters["user/isSuperAdmin"]) {
      next("/admin");
    }
  });
};

const ifAdmin = (to, from, next) => {
  store.dispatch("user/get_profile").then(() => {
    if (store.getters["user/isAdmin"] || store.getters["user/isSuperAdmin"]) {
      next();
      return;
    } else {
      next("/");
    }
  });
};

const ifSuperAdmin = (to, from, next) => {
  store.dispatch("user/get_profile").then(() => {
    if (store.getters["user/isSuperAdmin"]) {
      next();
      return;
    } else {
      next("/");
    }
  });
};

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      component: App,
      children: [
        {
          path: "/",
          name: "login",
          component: Login,
          beforeEnter: ifNotAuthenticated
        },
        {
          path: "/superadmin",
          name: "superadmin",
          component: SuperAdmin,
          beforeEnter: ifSuperAdmin
        },
        {
          path: "/reset-password",
          name: "reset-password",
          component: ForgotPassword,
          meta: {
            auth: false
          },
          beforeEnter: ifNotAuthenticated
        },
        {
          path: "/reset-password/:token/:email",
          name: "reset-password-form",
          component: ResetPasswordForm,
          meta: {
            auth: false
          },
          beforeEnter: ifNotAuthenticated
        },
        {
          path: "/admin",
          name: "admin",
          component: Admin,
          beforeEnter: ifAdmin
        }
      ]
    }
  ]
});

export default router;
