const state = {
  alerts: []
};

const getters = {
  getAlerts: state => state.alerts
};

const actions = {
  show({ commit }, alert) {
    commit("add_alert", alert);
  },
  hide({ commit }, alert) {
    commit("hide_alert", alert);
  }
};
const mutations = {
  add_alert(state, alert) {
    state.alerts.push(alert);
  },
  hide_alert(state, alert) {
    state.alerts.splice(state.alerts.indexOf(alert), 1);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
