<template>
  <v-app>
    <nav-bar :items="navbar_items"></nav-bar>
    <v-main style="background-color: #eee">
      <v-alert
        v-for="(alert, index) in getAlerts"
        :key="index"
        :type="alert.type"
        border="right"
        colored-border
        elevation="2"
        dismissible
        @input="closeAlert"
        :color="alert.type == 'info' ? 'primary' : ''"
      >
        {{ alert.message }}
      </v-alert>
      <router-view></router-view>
    </v-main>

    <v-footer class="font-weight-medium" color="primary" dark>
      <v-col class="text-center" cols="12">
        {{ new Date().getFullYear() }} —
        <strong>Telefactory AS</strong>
      </v-col>
    </v-footer>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import { settings } from "@/settings";

export default {
  name: "app",
  data: () => ({
    navbar_items: [
      {
        icon: "mdi-tools",
        text: "Admin",
        route: "/admin",
        role: "admin"
      },
      {
        icon: "mdi-tools",
        text: "SuperAdmin",
        route: "/superadmin",
        role: "superadmin"
      }
    ]
  }),
  methods: {
    closeAlert(v) {
      this.$store.dispatch("alert/hide", v);
    }
  },
  computed: {
    ...mapGetters("auth", ["isAuthenticated"]),
    ...mapGetters("alert", ["getAlerts"])
  }
};
</script>
