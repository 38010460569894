import { apiCall, api_routes } from "@/utils/api";

const state = {
  status: "",
  profile: {},
  is_admin: false,
  is_superadmin: false,
  is_customer: false,
  users: {},
  roles: {}
};

const getters = {
  getProfile: state => state.profile,
  isProfileLoaded(state) {
    return () => (state.profile.name ? true : false);
  },
  isAdmin: state => state.is_admin,
  isSuperAdmin: state => state.is_superadmin,
  isCustomer: state => state.is_customer,
  getUsers: state => state.users,
  getRoles: state => state.roles
};

const actions = {
  get_profile({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      apiCall({ url: api_routes.user.me, method: "get" })
        .then(resp => {
          commit("login_success", resp.data);
          resolve(resp);
        })
        .catch(err => {
          commit("login_fail", err);
          // if resp is unauthorized, logout, too
          dispatch("auth/logout", {}, { root: true });
          reject(err);
        });
    });
  },
  get({ commit }, request) {
    if (!request) {
      request = {
        page: "",
        query: "",
        itemsPerPage: -1,
        sortBy: "",
        sortDesc: ""
      };
    }
    return new Promise((resolve, reject) => {
      apiCall({
        url:
          api_routes.user.get +
          "?page=" +
          request.page +
          "&query=" +
          request.query +
          "&sortBy=" +
          request.sortBy +
          "&sortDesc=" +
          request.sortDesc +
          "&itemsPerPage=" +
          request.itemsPerPage,
        method: "get"
      })
        .then(resp => {
          commit("get_users_success", resp.data);
          resolve(resp.data);
        })
        .catch(err => {
          commit("admin_error", err);
          reject(err);
        });
    });
  },
  get_roles({ commit }) {
    return new Promise((resolve, reject) => {
      apiCall({
        url: api_routes.user.roles,
        method: "get"
      })
        .then(resp => {
          commit("get_roles_success", resp);
          resolve(resp);
        })
        .catch(err => {
          commit("admin_error", err);
          reject(err);
        });
    });
  },
  update({ commit }, request) {
    return new Promise((resolve, reject) => {
      apiCall({
        url: api_routes.user.update + "/" + request.id,
        data: request,
        method: "put"
      })
        .then(resp => {
          commit("admin_success", resp);
          resolve(resp);
        })
        .catch(err => {
          commit("admin_error", err);
          reject(err);
        });
    });
  },

  create({ commit }, request) {
    return new Promise((resolve, reject) => {
      apiCall({
        url: api_routes.user.create,
        data: request,
        method: "post"
      })
        .then(resp => {
          commit("admin_success", resp);
          resolve(resp);
        })
        .catch(err => {
          commit("admin_error", err);
          reject(err);
        });
    });
  },
  delete({ commit }, request) {
    return new Promise((resolve, reject) => {
      apiCall({
        url: api_routes.user.delete + "/" + request.id,
        data: request,
        method: "delete"
      })
        .then(resp => {
          commit("admin_success", resp);
          resolve(resp);
        })
        .catch(err => {
          commit("admin_error", err);
          reject(err);
        });
    });
  }
};

const mutations = {
  login_success(state, resp) {
    state.status = "success";
    state.profile = resp;

    for (let role of resp.roles) {
      if (role.name === "admin") {
        state.is_admin = true;
      }
      if (role.name === "superadmin") {
        state.is_superadmin = true;
      }
      if (role.name === "customer") {
        state.is_customer = true;
      }
    }
  },
  login_fail(state, err) {
    state.status = err;
  },
  get_users_success(state, resp) {
    state.users = resp.data;
  },
  get_roles_success(state, resp) {
    state.roles = resp.data;
  },
  admin_success(state, resp) {
    state.status = resp;
  },
  admin_error(state, err) {
    state.error = err;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
