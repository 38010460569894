<template>
  <span>
    <v-app-bar app color="white" class="elevation-1">
      <div class="d-flex align-center">
        <v-img
          alt="Telefactory logo"
          class="shrink mr-2"
          contain
          src="~@/assets/tf.png"
          transition="scale-transition"
          width="40"
        />
        <v-toolbar-title>
          <router-link to="/" tag="span" style="cursor: pointer">
            <h2>{{ appName }}</h2>
          </router-link>
        </v-toolbar-title>
      </div>

      <v-spacer></v-spacer>

      <div class="hidden-sm-and-down">
        <template>
          <v-btn
            color="primary"
            class="ma-1"
            v-for="(item, index) in allowedItems"
            :key="index"
            :to="item.route"
          >
            <v-icon>{{ item.icon }}</v-icon>
            {{ item.text }}
          </v-btn>
        </template>

        <v-btn
          color="primary"
          @click="logout()"
          class="ma-1"
          v-if="isAuthenticated"
        >
          <v-icon>mdi-logout</v-icon>Logout
        </v-btn>
      </div>
      <v-app-bar-nav-icon
        v-if="allowedItems.length > 0"
        class="hidden-md-and-up"
        @click="drawer = true"
      ></v-app-bar-nav-icon>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" app temporary right>
      <v-list nav dense>
        <v-list-item-group v-model="group">
          <v-list-item
            v-for="(item, index) in allowedItems"
            :key="index"
            :to="item.route"
          >
            <v-list-item-icon>
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="item.text"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="isAuthenticated" @click="logout()">
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Log out</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </span>
</template>
<script>
import { settings } from "@/settings";
import { mapGetters } from "vuex";

export default {
  props: {
    items: Array
  },
  data: () => ({
    drawer: false,
    group: 1
  }),
  name: "navbar",
  methods: {
    logout() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/");
      window.location.reload();
    }
  },
  computed: {
    ...mapGetters("user", ["getProfile"]),
    ...mapGetters("auth", ["isAuthenticated"]),

    allowedItems() {
      let items = [];
      for (let item of this.items) {
        if (this.getProfile && this.getProfile.roles) {
          for (let role of this.getProfile.roles) {
            if (item.role === role.name) {
              items.push(item);
            }
          }
        }
        if (item.role === "all") {
          items.push(item);
        }
      }
      return items;
    },
    appName() {
      return settings.APP_NAME;
    }
  }
};
</script>
<style></style>
