<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-card class="elevation-1 mt-4">
          <v-toolbar color="primary" dark flat>
            <v-toolbar-title>SuperAdmin</v-toolbar-title>
          </v-toolbar>
          <v-tabs v-model="tab">
            <v-tab>Providers</v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item :eager="true">
              <data-table
                :headers="getProviderHeaders()"
                :get_action="`provider/get`"
                :update_action="`provider/update`"
                :create_action="`provider/create`"
                :delete_action="`provider/delete`"
                :singular="`provider`"
              ></data-table>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { settings } from "@/settings";
import { mapGetters } from "vuex";

export default {
  name: "superadmin",
  data() {
    return {
      tab: null,
      group: null
    };
  },
  methods: {
    getProviderHeaders() {
      return [
        {
          text: "Created",
          align: "start",
          sortable: true,
          value: "created_at"
        },
        {
          text: "Company name",
          value: "company_name",
          updatable: true,
          required: true,
          type: "string"
        },
        {
          text: "Contact",
          value: "contact_person",
          updatable: true,
          required: false,
          type: "string"
        },
        {
          text: "Phone",
          value: "contact_number",
          updatable: true,
          required: false,
          type: "string"
        },
        {
          text: "Epost",
          value: "contact_email",
          updatable: true,
          required: false,
          type: "email"
        },
        {
          text: "TSIP Provider_ID",
          value: "tsip_provider_id",
          updatable: false,
          required: true,
          type: "string"
        },
        { text: "Action", value: "actions", sortable: false, align: "end" }
      ];
    }
  },
  computed: {
    ...mapGetters("user", ["getProfile", "getRoles"]),
    ...mapGetters("provider", ["getProviders"])
  },
  mounted() {
    this.$store.dispatch("user/get_roles");
    this.$store.dispatch("provider/get");
  }
};
</script>
