import Vue from "vue";
import Vuex from "vuex";
import auth from "./modules/auth";
import user from "./modules/user";
import provider from "./modules/provider";
import bambora_payment_options from "./modules/bambora_payment_options";
import arvato_invoice_options from "./modules/arvato_invoice_options";
import alert from "./modules/alert";

import settings from "./modules/settings";
Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

export default new Vuex.Store({
  modules: {
    auth,
    user,
    provider,
    bambora_payment_options,
    arvato_invoice_options,
    alert,
    settings
  },
  strict: true
});
