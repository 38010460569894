import axios from "axios";
import { settings } from "@/settings";
import store from "../store";

const API_SERVER = settings.API_SERVER;

export const api_routes = {
  user: {
    login: API_SERVER + "login",
    //signup: API_SERVER + "register",
    me: API_SERVER + "me",
    forgotpassword: API_SERVER + "auth/reset-password",
    passwordreset: API_SERVER + "auth/reset/password",
    get: API_SERVER + "admin/users",
    update: API_SERVER + "admin/users",
    create: API_SERVER + "admin/users",
    delete: API_SERVER + "admin/users",
    roles: API_SERVER + "roles"
  },
  provider: {
    get: API_SERVER + "admin/providers",
    update: API_SERVER + "admin/providers",
    create: API_SERVER + "admin/providers",
    delete: API_SERVER + "admin/providers"
  },
  bambora_payment_options: {
    get: API_SERVER + "admin/bambora",
    update: API_SERVER + "admin/bambora",
    create: API_SERVER + "admin/bambora",
    delete: API_SERVER + "admin/bambora"
  },
  arvato_invoice_options: {
    get: API_SERVER + "admin/arvato",
    update: API_SERVER + "admin/arvato",
    create: API_SERVER + "admin/arvato",
    delete: API_SERVER + "admin/arvato"
  }
};

export const apiCall = ({ url, method, ...args }) =>
  new Promise((resolve, reject) => {
    let token = localStorage.getItem("user-token") || "";

    if (token)
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;

    try {
      axios({
        method: method || "get",
        url: url,
        ...args
      })
        .then(resp => {
          resolve(resp.data);
        })
        .catch(error => {
          store.dispatch("alert/show", {
            message: error,
            type: "error"
          });
          reject(error);
        });
    } catch (err) {
      reject(new Error(err));
    }
  });
