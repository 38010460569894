<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :loading="loading"
    :options.sync="options"
    :server-items-length="total"
    @click:row="routeTo"
    :class="{
      'tf-pointer': router_link,
      'mobile-stripes': $vuetify.breakpoint.xs
    }"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title class="pr-2" v-if="title && total > 1">{{
          title
        }}</v-toolbar-title>
        <v-text-field
          v-if="total > 10 || options.query !== ''"
          @input="getResults()"
          v-model="options.query"
          append-icon="mdi-magnify"
          label="Søk"
          single-line
          hide-details
          class="mr-5 ma-md-0"
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="500px">
          <template v-if="create_action" v-slot:activator="{ on }">
            <v-btn color="primary" dark v-on="on" @click="onCreate()">
              <v-icon>mdi-plus</v-icon>
              {{ singular }}
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>
            <v-card-text>
              <v-form v-model="is_valid">
                <v-row>
                  <v-col>
                    <div v-for="header in headers" :key="header.value">
                      <v-text-field
                        v-if="header.updatable && header.type === 'string'"
                        v-model="edited_item[header.value]"
                        :label="header.text"
                        :rules="[requiredRule(header.text, header.required)]"
                      ></v-text-field>

                      <v-text-field
                        v-if="header.updatable && header.type === 'amount'"
                        v-model="edited_item[header.value]"
                        :label="header.text"
                        :rules="[
                          amountRule(header),
                          requiredRule(header.text, header.required)
                        ]"
                        error-count="2"
                      ></v-text-field>
                      <v-text-field
                        v-if="header.updatable && header.type === 'email'"
                        v-model="edited_item[header.value]"
                        :label="header.text"
                        :rules="[
                          emailRule(),
                          requiredRule(header.text, header.required)
                        ]"
                        error-count="2"
                        type="email"
                      ></v-text-field>
                      <v-text-field
                        :error-messages="errors"
                        v-if="header.updatable && header.type === 'phone'"
                        v-model="edited_item[header.value]"
                        :label="header.text"
                        :rules="[
                          phoneRule(),
                          requiredRule(header.text, header.required)
                        ]"
                        error-count="2"
                      ></v-text-field>
                      <v-text-field
                        v-if="header.updatable && header.type === 'orgnumber'"
                        v-model="edited_item[header.value]"
                        :label="header.text"
                        :rules="[
                          orgNumberRule(),
                          requiredRule(header.text, header.required)
                        ]"
                      ></v-text-field>
                      <v-textarea
                        v-if="header.updatable && header.type === 'textfield'"
                        v-model="edited_item[header.value]"
                        :label="header.text"
                        :rules="[requiredRule(header.text, header.required)]"
                      ></v-textarea>
                      <v-checkbox
                        v-if="header.updatable && header.type === 'boolean'"
                        v-model="edited_item[header.value]"
                        :label="header.text"
                      ></v-checkbox>
                      <v-select
                        v-if="header.updatable && header.type === 'select'"
                        :item-text="header.item_text"
                        :item-value="header.item_value"
                        v-model="edited_item[header.parent_id]"
                        :rules="[requiredRule(header.text, header.required)]"
                        :items="header.items"
                        :label="header.text"
                        :clearable="header.clearable"
                        v-on:click:clear="edited_item[header.parent] = null"
                        v-on:change="selectUpdated(header, edited_item)"
                      >
                      </v-select>
                      <v-text-field
                        v-if="header.updatable && header.type === 'date'"
                        type="date"
                        v-model="edited_item[header.value]"
                        :label="header.text"
                        :rules="[requiredRule(header.text, header.required)]"
                        :clearable="
                          edited_item[header.value] && header.clearable
                        "
                      ></v-text-field>
                      <v-select
                        v-if="header.type === 'roles' && header.updatable"
                        v-model="edited_item.roles"
                        :items="header.items"
                        item-text="name"
                        :menu-props="{ maxHeight: '400' }"
                        :label="header.text"
                        multiple
                      ></v-select>
                    </div>
                    <div v-if="change_password">
                      <v-checkbox
                        v-if="editing"
                        v-model="toggle_change_password"
                        label="Endre passord"
                      ></v-checkbox>
                      <v-text-field
                        type="password"
                        v-if="toggle_change_password"
                        v-model="edited_item['password']"
                        label="Passord"
                        prepend-icon="lock"
                        :rules="[
                          requiredRule('Passord', true),
                          passwordLengthRule(8)
                        ]"
                        error-count="2"
                      ></v-text-field>
                      <v-text-field
                        type="password"
                        v-if="toggle_change_password"
                        v-model="edited_item['confirm_password']"
                        label="Bekreft passord"
                        prepend-icon="lock"
                        :rules="[
                          requiredRule('Bekreft passord', true),
                          v =>
                            v === edited_item.password ||
                            'Passordet matcher ikke'
                        ]"
                      ></v-text-field>
                    </div>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="close">Cancel</v-btn>
              <v-btn color="primary" text @click="save" :disabled="!is_valid"
                >Save</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon
        v-if="update_action"
        small
        class="mr-2"
        @click.stop="editItem(item)"
        >mdi-pencil</v-icon
      >
      <v-icon v-if="delete_action" small @click.stop="deleteItem(item)"
        >mdi-delete</v-icon
      >
    </template>
    <template v-slot:item.created_at="{ item }">
      {{ item.created_at | toDate }}
    </template>
    <template v-slot:item.roles="{ item }">
      {{ item.roles.map(e => e.name).join(", ") }}
    </template>
  </v-data-table>
</template>
<script>
import moment from "moment";
import { settings } from "@/settings";
import validationRules from "@/validation/rules.js";

export default {
  props: {
    headers: Array,
    get_action: String,
    update_action: String,
    create_action: String,
    delete_action: String,
    router_link: Boolean,
    parent_name: String,
    parent_id: Number,
    title: String,
    singular: String,
    change_password: Boolean,
    show_products_select: Boolean
  },
  data: () => ({
    total: 0,
    items: [],
    errors: [],
    edited_item: {
      create_user: true
    },
    options: {
      query: ""
    },
    dialog: false,
    editing: false,
    loading: true,
    is_valid: true,
    toggle_change_password: false
  }),
  name: "datatable",
  methods: {
    getResults() {
      this.loading = true;
      clearTimeout(this._timerId);
      this._timerId = setTimeout(() => {
        this.$store
          .dispatch(this.get_action, {
            parent_id: this.parent_id,
            ...this.options
          })
          .then(res => {
            this.items = res.data;
            this.total = res.total;
          })
          .finally(() => {
            this.loading = false;
          });
      }, 200);
    },
    onCreate($event) {
      this.toggle_change_password = true;
    },
    editItem(item) {
      this.edited_item = Object.assign({}, item);
      this.dialog = true;
      this.editing = true;
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editing = false;
        this.toggle_change_password = false;
        this.edited_item = { create_user: true };
      });
    },
    save() {
      if (this.editing) {
        this.updateItem();
      } else {
        this.createItem();
      }
      this.close();
    },
    updateItem() {
      this.loading = true;
      this.$store
        .dispatch(this.update_action, {
          ...this.edited_item
        })
        .then(() => {
          this.getResults();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    createItem() {
      this.loading = true;
      this.$store
        .dispatch(this.create_action, {
          parent_id: this.parent_id,
          ...this.edited_item
        })
        .then(() => {
          this.getResults();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    deleteItem(item) {
      confirm("Er du sikker på at du ønsker å slette denne raden?") &&
        this.$store
          .dispatch(this.delete_action, {
            ...item
          })
          .then(() => {
            this.getResults();
          })
          .finally(() => {
            this.loading = false;
          });
    },
    routeTo($event) {
      if (this.router_link) {
        this.$router.push({
          name: "edit-customer",
          params: { id: $event.id }
        });
      }
    },
    selectUpdated(header, item) {},
    ...validationRules
  },
  computed: {
    formTitle() {
      return this.editing ? "Edit " + this.singular : "Create " + this.singular;
    }
  },
  filters: {
    toDate: date => moment(date).format("YYYY-MM-DD")
  },
  watch: {
    options: {
      handler() {
        this.getResults();
      },
      deep: true
    },
    dialog(val) {
      val || this.close();
    }
  }
};
</script>
<style>
.tf-pointer tr {
  cursor: pointer;
}
.mobile-stripes tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
  display: block !important;
}
.v-slide-group__prev {
  display: none !important;
}
</style>
