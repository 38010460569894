<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-card class="elevation-1 mt-4">
          <v-toolbar color="primary" dark flat>
            <v-toolbar-title>
              Admin
            </v-toolbar-title>
          </v-toolbar>
          <v-tabs v-model="tab">
            <v-tab>Users</v-tab>
            <v-tab>Bambora Payment Options</v-tab>
            <v-tab>Arvato Invoice Options</v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item :eager="true">
              <data-table
                :headers="getUserHeaders()"
                :get_action="`user/get`"
                :update_action="`user/update`"
                :create_action="`user/create`"
                :delete_action="`user/delete`"
                :singular="`user`"
                :change_password="true"
              ></data-table>
            </v-tab-item>
            <v-tab-item :eager="true">
              <data-table
                :headers="getPaymentOptionsHeaders()"
                :get_action="`bambora_payment_options/get`"
                :update_action="`bambora_payment_options/update`"
                :create_action="`bambora_payment_options/create`"
                :delete_action="`bambora_payment_options/delete`"
                :singular="`bambora payment option`"
              ></data-table>
            </v-tab-item>
            <v-tab-item :eager="true">
              <data-table
                :headers="getArvatoInvoicesHeaders()"
                :get_action="`arvato_invoice_options/get`"
                :update_action="`arvato_invoice_options/update`"
                :create_action="`arvato_invoice_options/create`"
                :delete_action="`arvato_invoice_options/delete`"
                :singular="`arvato invoice option`"
              ></data-table>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { settings } from "@/settings";
import { mapGetters } from "vuex";

export default {
  data: () => ({
    tab: null
  }),
  name: "admin",
  methods: {
    getUserHeaders() {
      return [
        {
          text: "Created",
          align: "start",
          sortable: true,
          value: "created_at"
        },
        {
          text: "Name",
          value: "name",
          updatable: true,
          required: true,
          type: "string"
        },
        {
          text: "Main number",
          value: "main_number",
          updatable: true,
          required: true,
          type: "string"
        },
        {
          text: "Email",
          value: "email",
          updatable: true,
          required: true,
          type: "email"
        },
        {
          text: "Roles",
          value: "roles",
          updatable: true,
          required: false,
          sortable: false,
          type: "roles",
          items: this.getRoles
        },
        {
          text: "Provider",
          value: "provider.company_name",
          updatable: true,
          required: false,
          type: "select",
          item_text: "company_name",
          item_value: "id",
          parent: "provider",
          parent_id: "provider_id",
          items: this.getProviders
        },
        { text: "Action", value: "actions", sortable: false, align: "end" }
      ];
    },
    getPaymentOptionsHeaders() {
      return [
        {
          text: "Created",
          align: "start",
          sortable: true,
          value: "created_at"
        },
        {
          text: "Merchant Number",
          value: "merchant_number",
          updatable: true,
          required: true,
          type: "string"
        },
        {
          text: "Access Key",
          value: "access_key",
          updatable: true,
          required: true,
          type: "string"
        },
        {
          text: "Secret Key",
          value: "secret_key",
          updatable: true,
          required: true,
          type: "string"
        },
        {
          text: "MD5 Key",
          value: "md5_key",
          updatable: true,
          required: false,
          type: "string"
        },
        {
          text: "Token scope",
          value: "token_scope",
          updatable: true,
          required: false,
          type: "string"
        },
        {
          text: "Provider",
          value: "provider.company_name",
          updatable: true,
          required: false,
          type: "select",
          item_text: "company_name",
          item_value: "id",
          parent: "provider",
          parent_id: "provider_id",
          items: this.getProviders
        },

        { text: "Action", value: "actions", sortable: false, align: "end" }
      ];
    },
    getArvatoInvoicesHeaders() {
      return [
        {
          text: "Created",
          align: "start",
          sortable: true,
          value: "created_at"
        },
        {
          text: "Client ID",
          value: "client_id",
          updatable: true,
          required: true,
          type: "string"
        },
        {
          text: "Username",
          value: "username",
          updatable: true,
          required: true,
          type: "string"
        },
        {
          text: "Password",
          value: "password",
          updatable: true,
          required: true,
          type: "string"
        },
        {
          text: "Provider",
          value: "provider.company_name",
          updatable: true,
          required: false,
          type: "select",
          item_text: "company_name",
          item_value: "id",
          parent: "provider",
          parent_id: "provider_id",
          items: this.getProviders
        },

        { text: "Action", value: "actions", sortable: false, align: "end" }
      ];
    }
  },
  computed: {
    ...mapGetters("user", ["getProfile", "isAdmin", "getRoles"]),
    ...mapGetters("provider", ["getProviders"])
  },
  mounted() {
    this.$store.dispatch("user/get_roles");
    this.$store.dispatch("provider/get");
  }
};
</script>
